<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Contratos</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tif</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Básico</li>
                  <li class="breadcrumb-item active">Contratos</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_tifcontratos_export"
                        v-if="$store.getters.can('tif.contratos.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        v-if="$store.getters.can('tif.contratos.create')"
                        @click="create()"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  id="contratos"
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                >
                  <thead>
                    <tr>
                      <th>
                        Contrato
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.no_contrato"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        <label> Empresa </label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="slct_empresa"
                          placeholder="Empresas"
                          label="razon_social"
                          class="form-control form-control-sm p-0"
                          :options="empresas"
                          :filterable="false"
                          @search="buscarEmpresas"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        Fecha Inicial
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_ini"
                          @change="getIndex()"
                        />
                      </th>
                      <th>
                        Fecha Final
                        <input
                          type="date"
                          class="form-control form-control-sm"
                          v-model="filtros.fecha_fin"
                          @change="getIndex()"
                        />
                      </th>
                      <th>Observaciones</th>
                      <th style="width: 150px">
                        Estado
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.estado"
                          @change="getIndex()"
                        >
                          <option value="">Seleccione...</option>
                          <option
                            v-for="estado in listasForms.estados"
                            :key="estado.numeracion"
                            :value="estado.numeracion"
                          >
                            {{ estado.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="contrato in contratos.data" :key="contrato.id">
                      <td>{{ contrato.no_contrato }}</td>
                      <td v-if="contrato.tipo_empresa == 1">
                        {{ contrato.empresa.razon_social }}
                      </td>
                      <td v-else>
                        <div
                          v-for="contratos_empresas in contrato.contratos_empresas"
                          :key="contratos_empresas.id"
                        >
                          {{ contratos_empresas.razon_social }}
                        </div>
                      </td>
                      <td class="text-center">{{ contrato.fecha_ini }}</td>
                      <td class="text-center">{{ contrato.fecha_fin }}</td>
                      <td class="text-wrap">
                        <small>{{ contrato.observaciones }}</small>
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-bind:class="[
                            contrato.estado == 1
                              ? 'badge-warning'
                              : 'badge-success',
                          ]"
                        >
                          {{ contrato.estado == 1 ? "Borrador" : "Definitivo" }}
                        </span>
                      </td>
                      <td style="width: 50px">
                        <div class="btn-group float-left">
                          <button
                            type="button"
                            v-if="$store.getters.can('tif.contratos.show')"
                            @click="visualizar(contrato)"
                            class="btn btn-sm bg-info"
                          >
                            <i class="fas fa-eye"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('tif.contratos.edit')"
                            @click="edit(contrato)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="
                              $store.getters.can('tif.contratos.delete') &&
                                contrato.estado == 1
                            "
                            @click="destroy(contrato.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="contratos.total">
                  <p>
                    Mostrando del <b>{{ contratos.from }}</b> al
                    <b>{{ contratos.to }}</b> de un total:
                    <b>{{ contratos.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="contratos"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <ContratosExport ref="ContratosExport" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Loading from "../../../../components/Loading";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import pagination from "laravel-vue-pagination";
import ContratosExport from "./ContratosExport";

export default {
  name: "ContratosIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    ContratosExport,
  },
  data() {
    return {
      cargando: false,
      file: "",
      filtros: {
        no_contrato: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        estado: null,
      },
      contratos: {},
      empresas: [],
      slct_empresa: [],
      otrosis: [],
      listasForms: {
        estados: [],
        tipos_contrato: [],
        objetos_contrato: [],
      },
      form: {
        tep_contrato_nacional_id: null,
        no_contrato: null,
        empresa_id: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
        pernocte: null,
        combustible: false,
        peajes: null,
      },
    };
  },
  validations: {
    form: {
      no_contrato: {
        required,
      },
      tipo_contrato: {
        required,
      },
      objeto_contrato: {
        required,
      },
      fecha_ini: {
        required,
      },
      fecha_fin: {
        required,
      },
      vigencia_poliza: {
        required,
      },
      valor: {
        required,
      },
      pernocte: {
        required,
      },
      combustible: {
        required,
      },
      peajes: {
        required,
      },
    },
  },
  methods: {
    getIndex(page = 1) {
      this.cargando = true;

      this.filtros.empresa_id = null;
      if (this.slct_empresa) {
        this.filtros.empresa_id = this.slct_empresa.id;
      }
      axios
        .get("/api/tif/contratos?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.contratos = response.data;
        });
    },

    getEstados() {
      axios.get("/api/lista/28").then((response) => {
        this.listasForms.estados = response.data;
      });
    },

    destroy(idContrato) {
      this.$swal({
        title: "Está seguro de eliminar este Contrato?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/tif/contratos/" + idContrato).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se eliminó el Contrato exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url, {
            params: { linea_negocio_id: [3] },
          })
          .then(function(response) {
            let respuesta = response.data;
            me.empresas = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    create() {
      return this.$router.push({
        name: "/Tif/ContratoForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(contrato) {
      return this.$router.push({
        name: "/Tif/ContratoForm",
        params: { accion: "Editar", data_edit: contrato, id: contrato.id },
      });
    },

    visualizar(contrato) {
      return this.$router.push({
        name: "/Tif/ContratoForm",
        params: {
          accion: "Editar",
          data_edit: contrato,
          id: contrato.id,
          act: 2,
        },
      });
    },

    // Funciones para los otrosís
    getTipoContrato() {
      axios.get("/api/lista/26").then((response) => {
        this.listasForms.tipos_contrato = response.data;
      });
    },

    getObjetoContrato() {
      axios.get("/api/lista/27").then((response) => {
        this.listasForms.objetos_contrato = response.data;
      });
    },

    llenarModal(contrato) {
      this.form = {
        tep_contrato_nacional_id: contrato.id,
        empresa_id: contrato.empresa_id,
        no_contrato: null,
        fecha_ini: null,
        fecha_fin: null,
        valor: null,
        pernocte: null,
        combustible: false,
        peajes: null,
      };

      this.otrosis = contrato.otrosis;
    },

    validaFechas() {
      if (this.form.fecha_ini && this.form.fecha_fin) {
        const fecha_menor = new Date(this.form.fecha_ini);
        const fecha_mayor = new Date(this.form.fecha_fin);
        // Se valida que la fecha inicial sea menor que la fecha final
        if (fecha_menor > fecha_mayor) {
          this.form.fecha_fin = null;
          this.$swal({
            icon: "error",
            title: `La fecha inicial no puede ser mayor a la fecha final...`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        }
      }
    },

    save() {
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/tep/contratosNacionales/otrosis",
          data: this.form,
        })
          .then(() => {
            this.getIndex();
            this.$refs.closeModal.click();
            this.$swal({
              icon: "success",
              title: "El otrosí se guardó exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },
  },
  mounted() {
    this.getIndex();
    this.getEstados();
    this.getTipoContrato();
    this.getObjetoContrato();
  },
};
</script>
